import { FC } from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import styled, { css } from 'styled-components';

export interface ISpinnerProps extends CircularProgressProps {
  centered?: boolean;
  wrapperClassName?: string;
}

const SpinnerWrapper = styled.div<{ centered?: boolean }>`
  position: absolute;

  ${({ centered }) =>
    centered &&
    css`
      width: 50%;
      height: 50%;
      transform: translate(50%, 50%);
    `};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.grey[500]};
`;

export const Spinner: FC<ISpinnerProps> = ({ centered, wrapperClassName, ...rest }) => (
  <SpinnerWrapper className={wrapperClassName} centered={centered} role='progressbar'>
    <CircularProgressStyled {...rest} />
  </SpinnerWrapper>
);
