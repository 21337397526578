import styled, { keyframes } from 'styled-components';

interface ITextAnimationStyled {
  color: string;
  withBorder?: boolean;
}

export const textAnimation = (color: string) => keyframes`
0% {
  color: inherit;
  border-color: inherit;
}
50% {
  color: ${color};
  border-color: ${color};
}
100% {
  color: inherit;
  border-color: inherit;
}
`;

export const TextAnimation = styled.span<ITextAnimationStyled>`
  animation-iteration-count: infinite;
  color: inherit;
  animation-name: ${({ color }) => {
    return textAnimation(color);
  }};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: alternate;
  border: ${({ withBorder }) => (!withBorder ? 'none' : '1.5px solid')};
  padding: ${({ withBorder }) => (!withBorder ? 'inherit' : '2px 4px')};
  border-radius: 5px;
`;
