import styled from 'styled-components';
import { IconButton, Input, TableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterAlt } from '@mui/icons-material';

import { IThemeDependentStyledComponentProps, ThemeMode } from '/common/models';
import { ThemeProvider } from '/common';
import { TextFieldValidator } from '/shared/TextFieldValidator';

interface IVisibleEl {
  show?: boolean;
}

export const HeaderCell = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const SortCell = styled.div`
  position: relative;

  /* padding-right: 5px; */
`;

export const SortLabel = styled(TableSortLabel)`
  padding-right: ${(props) => (props.active ? 0 : '5px')};

  & > svg {
    font-size: 1.25em;
    margin: 0;
  }
` as typeof TableSortLabel;

export const CellLabel = styled.span`
  padding-right: 5px;
  white-space: nowrap;
`;

export const SortClearButton = styled(IconButton)`
  && {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
` as typeof IconButton;

export const FilterAltIcon = styled(FilterAlt)`
  /* transition: color 100ms linear; */
` as typeof FilterAlt;

export const FilterButton = styled.button`
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  ${(props: IVisibleEl) =>
    !props.show &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const FilterForm = styled.div`
  padding: 8px;
`;

export const ColumnFilter = styled.div`
  position: absolute;
  bottom: -15px;
  left: 0;
  max-width: calc(100% - 30px);
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 18px;
  align-items: center;
`;

export const FilterLabel = styled.div<IThemeDependentStyledComponentProps>`
  overflow: hidden;
  color: ${({ $themePalette }: IThemeDependentStyledComponentProps) =>
    ThemeProvider.getAppropriateStyles(
      $themePalette?.mode as ThemeMode,
      $themePalette?.primary.main as string,
      $themePalette?.text.primary as string
    )};
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: lowercase;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ColumnFilterClear = styled.button<IThemeDependentStyledComponentProps>`
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: opacity 100ms linear;
  height: 13px;
  width: 13px;
  margin-left: 5px;

  svg {
    font-size: 13px;

    &:hover {
      color: ${({ $themePalette }: IThemeDependentStyledComponentProps) => $themePalette?.error.main};
    }
  }
`;

export const FilterInput = styled(Input)<IThemeDependentStyledComponentProps>`
  padding-right: 3px;

  svg {
    transition: color 100ms linear;

    &:hover {
      color: ${({ $theme }: IThemeDependentStyledComponentProps) => $theme?.palette.action.hover};
    }
  }

  input {
    padding: 10px 3px 10px 7px;
    font-size: 14px;
  }
`;

export const FilterTextFieldValidator = styled(TextFieldValidator)<IThemeDependentStyledComponentProps>`
  padding-right: 3px;
  margin-bottom: 4px;

  svg {
    transition: color 100ms linear;

    &:hover {
      color: ${({ $theme }: IThemeDependentStyledComponentProps) => $theme?.palette.action.hover};
    }
  }

  input {
    padding: 10px 3px 10px 7px;
    font-size: 14px;
  }
`;

export const FilterClearButton = styled.button`
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: opacity 100ms linear;
  ${(props: IVisibleEl) =>
    !props.show &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const FilterSubmitButton = FilterClearButton;

export const usePopoverPaperClasses = makeStyles({
  root: {
    overflow: 'visible',
  },
});

export const useTimeFilterClasses = makeStyles({
  expression: {
    padding: '8px 8px 0 8px',
  },
  dateTimePicker: {
    minHeight: '205px',
  },
});
