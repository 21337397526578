import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  EntityPromotionsResponse,
  Environment,
  EnvironmentPromotionsResponse,
  PromotionResponse,
  PromotionScheduled,
  PromotionStateHelpers,
  SpacePromotionResponse,
} from '@playq/octopus-common';
import { Space } from '@playq/octopus2-apps';

import { IPromotedEntityWithVersion, useBulkDemoteScripted, useBulkPromoteScripted } from '/api/hooks/promotions';
import { activeSpaceToolkit, authToolkit } from '/store';
import { IDLClassWithSerialize } from '/common/models/interfaces';
import { snackbarService } from '/common/snackbarService';
import { getSandboxPromotion, isPromotionLoading } from '/shared/Promotions/helpers';
import { IRowData } from '/shared/Table';
import { GenericTableToolbar } from '/shared/Table/components/TableToolbar';
import { IPromotionHandlingParams } from '/shared/Promotions/components/EnvironmentForm/types';
import { useScriptsMetadata } from '/common/scripts';

import { StyledPromotionRow } from './styles';
import { ICreatePromotionToolbarProps, IPromotionToolbarDef, IPromotionToolbarProps } from './types';

export function PromotionToolbar<
  ID extends IDLClassWithSerialize<string>,
  Entity extends IDLClassWithSerialize,
  EV extends IPromotedEntityWithVersion<ID> = IPromotedEntityWithVersion<ID>,
>(props: IPromotionToolbarProps<ID, Entity, EV>) {
  const {
    rows,
    service,
    promotions,
    canWrite,
    canWriteLive,
    singleEnvToUse: singleEnvToUseProvided,
    scriptHooksNames,
    onChange,
    selectID,
    entityWithVersion,
    spaceLess,
  } = props;

  const user = useSelector(authToolkit.selectors.user);
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const space = spaceLess ? undefined : activeSpace;
  const [demotionPending, setDemotionPending] = useState<Environment | undefined>(undefined);
  const [promotionPending, setPromotionPending] = useState<Environment | undefined>(undefined);

  const { invokeScriptsHook } = useScriptsMetadata();

  const makePromotionResponseFromUpdatedEntity = (id: IPromotedEntityWithVersion<ID>) =>
    new PromotionResponse({
      author: user?.id.serialize() as string,
      authorName: user?.name,
      state: PromotionStateHelpers.serialize(new PromotionScheduled({ scheduledAt: new Date().toISOString() })),
      version: id.version,
      id: 0,
    });

  const mutateLivePromotion = (promotion: EntityPromotionsResponse, promotionResponse: PromotionResponse) => {
    if (promotion.live) {
      promotion.live.state = promotionResponse.state;
    } else {
      promotion.live = promotionResponse;
    }
  };

  const mutateSpacePromotion = (promotion: EntityPromotionsResponse, promotionResponse: PromotionResponse) => {
    let foundSpacePromotion = false;
    promotion.sandbox.custom = promotion.sandbox.custom.map((spacePromotion) => {
      if (spacePromotion.id.serialize() === space?.id.serialize()) {
        foundSpacePromotion = true;
        const updatedSpacePromotion = new SpacePromotionResponse(spacePromotion.serialize());
        updatedSpacePromotion.promotion.state = promotionResponse.state;
        return updatedSpacePromotion;
      }
      return spacePromotion;
    });
    if (!foundSpacePromotion) {
      promotion.sandbox.custom.push(
        new SpacePromotionResponse({
          id: space?.id.serialize() as string,
          spaceName: space?.name,
          promotion: promotionResponse.serialize(),
        })
      );
    }
  };

  const mutateSandboxPromotion = (promotion: EntityPromotionsResponse, promotionResponse: PromotionResponse) => {
    promotion.sandbox = new EnvironmentPromotionsResponse(promotion.sandbox.serialize());
    if (space) {
      mutateSpacePromotion(promotion, promotionResponse);
    } else if (promotion.sandbox.default_) {
      promotion.sandbox.default_.state = promotionResponse.state;
    } else {
      promotion.sandbox.default_ = promotionResponse;
    }
  };

  const handleUpdatePromotionsAfterPromote = (batch: IPromotedEntityWithVersion<ID>[], env: Environment) => {
    let updatedPromotions = [...(promotions || [])];

    batch.forEach((id) => {
      const originPromotion = promotions?.find((promotion) => promotion.id.serialize() === id.id.serialize());
      const promotionResponse = makePromotionResponseFromUpdatedEntity(id);
      const promotion = new EntityPromotionsResponse(originPromotion?.promotions.serialize());

      if (promotion.sandbox === undefined) {
        promotion.sandbox = new EnvironmentPromotionsResponse({ default: undefined, custom: [] });
      }

      if (env === Environment.Live) {
        mutateLivePromotion(promotion, promotionResponse);
      } else {
        mutateSandboxPromotion(promotion, promotionResponse);
      }

      const updatedPromotion = { id: id.id, promotions: promotion };

      if (originPromotion !== undefined) {
        updatedPromotions = updatedPromotions.map((p) =>
          p.id.serialize() === id.id.serialize() ? updatedPromotion : p
        );
      } else {
        updatedPromotions.push(updatedPromotion);
      }
    });

    onChange(updatedPromotions);
  };

  const { mutate: promoteBulk } = useBulkPromoteScripted<ID>(
    scriptHooksNames?.bulkPromote ? invokeScriptsHook : undefined,
    service,
    {
      onMutate: ({ serviceMethodArgs: [, env] }) => {
        setPromotionPending(env);
      },
      onSuccess: (_res, { serviceMethodArgs: [batch, env] }) => {
        snackbarService.success(`Scheduled to promote on ${env}`);
        if (user) {
          handleUpdatePromotionsAfterPromote(batch, env);
        }
      },
      onError: (error) => {
        setPromotionPending(undefined);
        snackbarService.genericFailure(error);
      },
    }
  );

  const handleUpdatePromotionsAfterDemote = (batch: IPromotedEntityWithVersion<ID>[], env: Environment) => {
    onChange(
      (promotions ?? []).map((prevPromotion) => {
        const needUpdate = batch.some((id) => id.id.serialize() === prevPromotion.id.serialize());
        if (needUpdate) {
          const promotion = new EntityPromotionsResponse(prevPromotion.promotions.serialize());
          if (env === Environment.Live) {
            promotion.live = undefined;
          } else if (space) {
            promotion.sandbox.custom = promotion.sandbox.custom.filter(
              (spacePromotion) => spacePromotion.id.serialize() !== space.id.serialize()
            );
          } else {
            promotion.sandbox.default_ = undefined;
          }
          return { id: prevPromotion.id, promotions: promotion };
        }
        return prevPromotion;
      })
    );
  };

  const { mutate: demoteBulk } = useBulkDemoteScripted<ID>(
    scriptHooksNames?.bulkDemote ? invokeScriptsHook : undefined,
    service,
    {
      onMutate: ({ serviceMethodArgs: [, env] }) => {
        setDemotionPending(env);
      },
      onSuccess: (_res, { serviceMethodArgs: [batch, env] }) => {
        snackbarService.success(`Demoted all from ${env}`);
        handleUpdatePromotionsAfterDemote(batch, env);
        // onMasterSelection(false);
      },
      onError: (error) => {
        setDemotionPending(undefined);
        snackbarService.genericFailure(error);
      },
    }
  );

  const selectedEntities = useMemo(
    () =>
      rows.reduce((acc: Entity[], next: IRowData<Entity>) => {
        return next.selected ? acc.concat(next.item) : acc;
      }, []),
    [rows]
  );

  const isAtEnv = useCallback(
    (env: Environment, id: ID, s?: Space) => {
      if (!promotions) {
        return false;
      }

      const promoInfo = promotions.find((p) => p.id.serialize() === id.serialize())?.promotions;
      if (promoInfo === undefined) {
        return false;
      }

      switch (env) {
        case Environment.Live:
          return Boolean(promoInfo.live);

        case Environment.Sandbox: {
          return getSandboxPromotion(promoInfo, s) !== undefined;
        }
      }
    },
    [promotions]
  );

  const hasDifferentVersion = useCallback(
    (env: Environment, id: ID, s: Space | undefined) => {
      const entity = selectedEntities.find((e) => selectID(e).serialize() === id.serialize());
      const promoInfo = promotions?.find((p) => p.id.serialize() === id.serialize())?.promotions;
      if (!entity || !promoInfo) {
        return true;
      }
      const version = entityWithVersion(entity).version;
      switch (env) {
        case Environment.Live: {
          return promoInfo.live?.version !== entityWithVersion(entity).version;
        }

        case Environment.Sandbox: {
          return getSandboxPromotion(promoInfo, s)?.version !== version;
        }
      }
    },
    [entityWithVersion, promotions, selectID, selectedEntities]
  );

  const canPromoteEnv = useCallback(
    (env: Environment, id: ID, s: Space | undefined) => {
      switch (env) {
        case Environment.Live:
          return canWriteLive && isAtEnv(Environment.Sandbox, id) && hasDifferentVersion(Environment.Live, id, s);
        case Environment.Sandbox:
          return canWrite && hasDifferentVersion(Environment.Sandbox, id, s);
      }
    },
    [canWrite, canWriteLive, hasDifferentVersion, isAtEnv]
  );

  const { sandboxEntities, liveEntities } = useMemo(() => {
    return selectedEntities.reduce(
      (acc: { sandboxEntities: EV[]; liveEntities: EV[] }, next: Entity) => {
        const id = selectID(next);
        const withVersion = entityWithVersion(next);

        if (canPromoteEnv(Environment.Sandbox, id, space)) {
          acc.sandboxEntities.push(withVersion);
        }

        if (canPromoteEnv(Environment.Live, id, space)) {
          acc.liveEntities.push(withVersion);
        }

        return acc;
      },
      { sandboxEntities: [], liveEntities: [] }
    );
  }, [selectedEntities, selectID, entityWithVersion, canPromoteEnv, space]);

  const promotionsPending = useMemo(() => {
    return {
      sandbox:
        promotionPending === Environment.Sandbox ||
        promotions?.some((p) => isPromotionLoading(getSandboxPromotion(p.promotions, space))),
      live: promotionPending === Environment.Live || promotions?.some((p) => isPromotionLoading(p.promotions.live)),
    };
  }, [promotionPending, promotions, space]);

  const getPromotionResponse = useCallback(
    (env: Environment, id: ID, s?: Space): PromotionResponse | undefined => {
      if (!promotions) {
        return;
      }

      const promRes = promotions.find((p) => p.id.serialize() === id.serialize())?.promotions;
      if (promRes === undefined) {
        return;
      }

      switch (env) {
        case Environment.Live:
          return promRes.live;
        case Environment.Sandbox:
          if (s) {
            const spacePromo = promRes.sandbox.custom.find(
              (sr: SpacePromotionResponse) => sr.id.serialize() === s.id.serialize()
            );

            return spacePromo?.promotion;
          }

          return promRes.sandbox.default_;
      }
    },
    [promotions]
  );

  const { demoteSandboxEntities, demoteLiveEntities } = useMemo(() => {
    return selectedEntities.reduce(
      (acc: { demoteSandboxEntities: EV[]; demoteLiveEntities: EV[] }, next: Entity) => {
        const id = selectID(next);
        const withSandboxVersion = entityWithVersion(next);
        const withLiveVersion = entityWithVersion(next);

        if (isAtEnv(Environment.Sandbox, id, space)) {
          const promRes = getPromotionResponse(Environment.Sandbox, id, space);
          if (promRes) {
            withSandboxVersion.version = promRes.version;
          }

          acc.demoteSandboxEntities.push(withSandboxVersion);
        }

        if (isAtEnv(Environment.Live, id)) {
          const promRes = getPromotionResponse(Environment.Live, id);
          if (promRes) {
            withLiveVersion.version = promRes.version;
          }

          acc.demoteLiveEntities.push(withLiveVersion);
        }

        return acc;
      },
      { demoteSandboxEntities: [], demoteLiveEntities: [] }
    );
  }, [selectedEntities, selectID, entityWithVersion, isAtEnv, space, getPromotionResponse]);

  const sandboxMsgBody =
    sandboxEntities.length === 0 ? 'Nothing to promote' : `${sandboxEntities.length} of ${selectedEntities.length}`;
  const liveMsgBody =
    liveEntities.length === 0 ? 'Nothing to promote' : `${liveEntities.length} of ${selectedEntities.length}`;

  const handlePromote = ({ env, singleEnvToUse }: IPromotionHandlingParams) => {
    const targetEnv: Environment = env ?? singleEnvToUse;
    const promoteIDs: EV[] = selectedEntities.reduce((acc: EV[], next: Entity) => {
      const id = selectID(next);
      if (canPromoteEnv(targetEnv, id, space)) {
        return acc.concat(entityWithVersion(next));
      }

      return acc;
    }, []);

    if (Environment.Live === targetEnv) {
      if (!canWriteLive) {
        return;
      }
      promoteIDs.forEach((promoteID) => {
        const sandboxPromotions = getSandboxPromotion(
          promotions?.find((p) => p.id.serialize() === promoteID.id.serialize())?.promotions
        );
        if (sandboxPromotions) {
          promoteID.version = sandboxPromotions.version;
        }
      });
    }
    promoteBulk({
      scriptsResolverArgs: scriptHooksNames?.bulkPromote ? [scriptHooksNames.bulkPromote, targetEnv] : undefined,
      serviceMethodArgs: [promoteIDs, targetEnv, space?.id],
    });
  };

  const handleDemote = ({ env, singleEnvToUse }: IPromotionHandlingParams) => {
    const targetEnv: Environment = env ?? singleEnvToUse;

    if (targetEnv === Environment.Live && !canWriteLive) {
      return;
    }
    const demoteEntities = targetEnv === Environment.Live ? demoteLiveEntities : demoteSandboxEntities;

    demoteBulk({
      scriptsResolverArgs: scriptHooksNames?.bulkDemote ? [scriptHooksNames.bulkDemote, targetEnv] : undefined,
      serviceMethodArgs: [demoteEntities, targetEnv, space?.id],
    });
  };

  const sandboxText =
    demotionPending === Environment.Sandbox
      ? 'Demoting...'
      : promotionsPending.sandbox
        ? 'Querying...'
        : sandboxMsgBody;

  const liveText =
    demotionPending === Environment.Live ? 'Demoting...' : promotionsPending.live ? 'Querying...' : liveMsgBody;

  const globalDisabled = promotionsPending.sandbox || promotionsPending.live || Boolean(demotionPending);
  return (
    <StyledPromotionRow
      onPromote={handlePromote}
      onDemote={handleDemote}
      sandboxState={{
        disablePromote: !canWrite || !sandboxEntities.length || globalDisabled,
        disableDemote: !canWrite || !demoteSandboxEntities.length || globalDisabled,
        loading: promotionsPending.sandbox || demotionPending === Environment.Sandbox,
        status: sandboxText,
      }}
      liveState={{
        disablePromote: !canWriteLive || !liveEntities.length || globalDisabled,
        disableDemote: !canWriteLive || !demoteLiveEntities.length || globalDisabled,
        loading: promotionsPending.live || demotionPending === Environment.Live,
        status: liveText,
      }}
      singleEnvToUse={singleEnvToUseProvided}
      spaceless={spaceLess}
    />
  );
}

export function createPromotionToolbar<
  ID extends IDLClassWithSerialize<string>,
  Entity extends IDLClassWithSerialize,
  EV extends IPromotedEntityWithVersion<ID>,
>(def: IPromotionToolbarDef<ID, Entity, EV>): FC<ICreatePromotionToolbarProps<ID, Entity>> {
  return (props: ICreatePromotionToolbarProps<ID, Entity>) => {
    const { selectedRowsCount } = props;

    if (selectedRowsCount === 0) {
      return <GenericTableToolbar<Entity> {...props} />;
    }

    return <PromotionToolbar<ID, Entity, EV> {...props} {...def} />;
  };
}
