import styled from 'styled-components';
import { Box, ListItem } from '@mui/material';
import { darken } from 'polished';

export const Wrapper = styled.div<{ mainColor: string; selected?: boolean }>`
  .MuiButtonGroup-root {
    border-radius: 16px;

    .MuiButtonGroup-groupedHorizontal {
      min-width: 0;
      opacity: ${(props) => (props.selected ? 0.7 : 1)};
      padding: 0 8px;
      background-color: ${(props) => props.mainColor};
      border-radius: 16px;
      white-space: nowrap;
      font-weight: bold;
      text-transform: none;

      &:not(:first-child) {
        padding: 0 4px 0 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      &:not(:last-child) {
        border-color: ${(props) => darken(0.2, props.mainColor)};
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
`;

export const ValuesWrapper = styled.div`
  min-width: 200px;
  padding: 8px 0;

  .MuiListItem-root:not(.MuiMenuItem-root) {
    padding-top: 0;
    padding-bottom: 0;

    & + .MuiMenuItem-root {
      margin-top: 6px;
    }
  }
`;

export const TextFieldItem = styled(ListItem)`
  padding-right: 6px;
  input {
    font-size: 0.875rem;
  }
` as typeof ListItem;

export const WrapperSort = styled(Box)`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 8px 16px;
`;
